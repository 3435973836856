<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>상담내역 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="name">이름</option>
          <option value="region">지역</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">답변상태</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label>
          <input type="radio" value="false" v-model="category" />
          답변대기
        </label>
        <label>
          <input type="radio" value="true" v-model="category" />
          답변완료
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one">
      <h2 class="bold">상담내역 리스트</h2>
      <table>
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 5%" />
          <col style="width: 10%" />
          <col style="width: 24%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 13%" />
          <col style="width: 15%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th scope="col">번호</th>
          <th scope="col">타입</th>
          <th scope="col">이름</th>
          <th scope="col">상담 내용</th>
          <th scope="col">플래너</th>
          <th scope="col">결혼 예정일</th>
          <th scope="col">예식 지역</th>
          <th scope="col">날짜</th>
          <th scope="col">답변상태</th>
        </tr>
        <tr v-for="(data, i) in consultList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>{{ data.consultType == "TOUR" ? "투어" : "일반" }}</td>
          <td>{{ data.author.username }} {{ data.type }}</td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.comment
            }}</router-link>
          </td>
          <td>{{ data.planner?.plannerName }}</td>
          <td>{{ moment(data.weddingDate).format("YYYY.MM.DD") }}</td>
          <td>{{ data.region }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td
            :class="{
              waiting: data.isReply == false,
              done: data.isReply == true,
            }"
          >
            {{ data.isReply == true ? "답변완료" : "답변대기" }}
          </td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="consultList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchConsultList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      consultList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
      adminType: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "7");
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  mounted() {
    this.getConsultList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.consultList = [];
      this.total = 0;
      this.getConsultList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getConsultList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getConsultList();
    },
    handleMove(id) {
      this.$router.push({ name: "consultreply", query: { id: id } });
    },
    getConsultList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        isReply: this.category,
      };
      if (this.adminType == "PLANNER") {
        params.plannerId = localStorage.getItem("plannerId");
      }
      fetchConsultList(params).then((res) => {
        if (res.data.status == 200) {
          this.consultList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
